import React, { useState, useRef } from 'react'
import Input from '~/components/input'
import Button from '~/components/button'

const apiUrl = process.env.GATSBY_STRAPI_URL || 'http://localhost:1337'

const PaymentFormEn = ({ option }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)
  const amountRef = useRef(null)
  const invoiceIdRef = useRef(null)

  const generateTransactionId = () =>
    Array.from({ length: 6 }, () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join('')

  const handleFormSubmit = async (e, isInvoice) => {
    e.preventDefault()
    setLoading(true)

    const paymentConf = {
      amount: amountRef.current?.value,
      firstName: firstNameRef.current?.value,
      lastName: lastNameRef.current?.value,
      email: emailRef.current?.value,
      invoiceId: isInvoice ? invoiceIdRef.current?.value : undefined,
      transactionId: generateTransactionId(),
    }

    try {
      const response = await fetch(`${apiUrl}/paiement`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentConf }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok.')
      }
      const formHtml = await response.text()
      const container = document.createElement('div')
      container.innerHTML = formHtml

      document.body.appendChild(container)

      const form = container.querySelector('form')
      if (form) {
        form.submit()
      } else {
        console.error('Form not found in the HTML response.')
      }
    } catch (error) {
      setMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  const isInvoice = option === 'invoice'

  return (
    <>
      <form
        onSubmit={e => handleFormSubmit(e, isInvoice)}
        className={`w-full mt-5 mb-4 px-3`}
      >
        <div className="text-center mt-2 mb-4 text-lg">Buyer information</div>
        <Input
          name="first_name"
          isRequired
          placeholder="First name"
          ref={firstNameRef}
        />
        <Input
          name="last_name"
          isRequired
          placeholder="Last name"
          ref={lastNameRef}
        />
        <Input
          name="email"
          type="email"
          size="30"
          isRequired
          placeholder="Email"
          ref={emailRef}
        />
        {isInvoice && (
          <Input
            name="invoice_id"
            isRequired
            placeholder="Invoice number"
            ref={invoiceIdRef}
          />
        )}
        {isInvoice && (
          <Input
            isRequired
            placeholder="Amount to pay (€ only)"
            type="number"
            min="1"
            max="15000"
            name="amount"
            ref={amountRef}
          />
        )}
        {!isInvoice && (
          <Input
            name="amount"
            isRequired
            placeholder="Amount to send (€ only)"
            type="number"
            min="1"
            max="1500"
            ref={amountRef}
          />
        )}
        {!isInvoice && (
          <div className="mt-4">
            <input
              className="cursor-pointer"
              id="approval"
              required
              type="checkbox"
            />
            <label className="mt-2 ml-1" htmlFor="approval">
              I certify I contacted Me Dufrêche before sending my caution.
            </label>
          </div>
        )}
        <div className="mt-3 mb-5">
          <input
            className="cursor-pointer"
            id="consent"
            required
            type="checkbox"
          />
          <label className="ml-1" htmlFor="consent">
            By submitting this form, I agree that the information entered will
            be transmitted and processed by Banque Populaire for the payment of
            my invoice. No personal data is stored by the current website via
            this form.
          </label>
        </div>
        <Button
          label={loading ? 'Loading...' : 'Go to payment'}
          type="submit"
          name="payer"
          value="Pay"
        />
        {message && <div data-test="payment-message">{message}</div>}
      </form>
    </>
  )
}

export default PaymentFormEn
